import React, { useState } from 'react';
import ContentContext from './ContentContext';

const ContentProvider = ({ children }) => {
  const [hasContent, setHasContent] = useState(false);
  return (
    <ContentContext.Provider value={{ hasContent, setHasContent }}>
      {children}
    </ContentContext.Provider>
  );
};

export default ContentProvider;

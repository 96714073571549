import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import MobileCategories from './MobileCategories';
import UIDrawer from '@jetshop/ui/Modal/Drawer';
import DrawerHeader from '../../../ui/DrawerHeader';
import SiteContentContext from '../../../SiteContent/SiteContentContext';
import Logo from '../Logo';
import CloseButton from '../../../ui/CloseButton';
import { headerHeight } from '../Header';
import { ScrollBox } from '../../../ui/ScrollBox';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image';
import { Above } from '@jetshop/ui/Breakpoints';
import { ChannelSelectorDropdown } from '../ChannelSelector/ChannelSelector';

const StyledCloseButton = styled(CloseButton)`
  padding: 10px;
  margin: -10px;
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  padding-bottom: 300px;
`;

const MobileNavWrapper = styled('div')`
  height: 100%;
  background: ${theme.colors.white};
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  background: ${theme.colors.white};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${theme.mobileHeader.nav.padding};
  margin: 0 0 1rem 0;
  border: none;
  border-bottom: 1px solid ${theme.colors.border};
  ${headerHeight}
`;

const LogoWrapper = styled('div')`
  padding: 10px 0;
  max-width: 100%;
  width: 80px;
  position: relative;
  left: -7px;
  a {
    display: block;
    svg {
      max-width: 100%;
    }
  }
`;

const NavLists = styled('div')`
  padding: 0.5rem ${theme.mobileHeader.nav.padding};
  background: ${theme.colors.white};
  ul {
    li {
      display: block;
      line-height: 42px;
      text-transform: uppercase;
      font-weight: ${theme.weights.semibold};
      font-size: ${theme.mobileHeader.nav.lv1.fontSize};
    }
  }
`;

const MenuImagesWrapper = styled('div')`
  padding: 0.5rem 1rem;
  //SLIDER WIDTH
  .slider-items {
    > a {
      margin-right: 2%;
      width: 42%;
      .image-wrapper {
      }
      text-align: center;
      p {
        margin-top: 5px;
        font-size: 0.9rem;
        font-weight: ${theme.weights.semibold};
      }
    }
  }
  .standard-nav {
    background: none;
    width: 20px;
    height: 20px;
    box-shadow: none;
    top: calc(50% - 20px);
    &.prev {
      left: -13px;
    }
    &.next {
      right: -13px;
    }
  }
`;

const ChannelSelectorWrapper = styled('div')`
  padding: 0 ${theme.mobileHeader.nav.padding};
  margin-top: 2rem;
  .channel-selector-wrapper {
    flex-direction: column;
  }
  .channel-selector-button {
    height: 36px;
  }
  .channel-selector-dropdown {
    position: static;
    box-shadow: none;
    width: 100%;
    padding: 1rem 0;
    border-top: 1px solid ${theme.colors.borderDark};
    border-bottom: 1px solid ${theme.colors.borderDark};
  }
`;

const MobileNav = ({
  categories,
  categoryTreeRoots,
  hideTarget,
  isOpen,
  right,
  ...rest
}) => {
  const { mobilePageList, menuImages } = useContext(SiteContentContext);
  return (
    <Above breakpoint="sm">
      {(matches) => {
        const size = matches ? 420 : 360;
        return (
          <UIDrawer isOpen={isOpen} right={!matches} left={matches} size={size}>
            <MobileNavWrapper>
              <StyledDrawerHeader>
                <LogoWrapper>
                  <Link to="/" onClick={hideTarget}>
                    <Logo mobile={true} />
                  </Link>
                </LogoWrapper>
                <StyledCloseButton onClick={hideTarget} />
              </StyledDrawerHeader>
              <Scroll>
                <MobileCategories
                  hideTarget={hideTarget}
                  categories={categoryTreeRoots}
                  categoryTreeRoots={categoryTreeRoots}
                  isOpen={isOpen}
                />
                <MenuImagesWrapper>
                  <ScrollBox showNavInMobile={true} endSpace={false}>
                    {menuImages?.map((button, index) => (
                      <Link
                        key={index}
                        to={button.link?.value}
                        onClick={hideTarget}
                      >
                        <div className="image-wrapper">
                          <Image
                            src={button.imageUrl?.value}
                            aspect={theme.productGrid.product.imageAspect}
                            sizes={[140]}
                            crop={true}
                            cover
                          />
                        </div>
                        <p>{button.name?.value}</p>
                      </Link>
                    ))}
                  </ScrollBox>
                </MenuImagesWrapper>
                <NavLists onClick={hideTarget}>{mobilePageList}</NavLists>
                <ChannelSelectorWrapper>
                  <ChannelSelectorDropdown />
                </ChannelSelectorWrapper>
              </Scroll>
            </MobileNavWrapper>
          </UIDrawer>
        );
      }}
    </Above>
  );
};
export default MobileNav;

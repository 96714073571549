import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { Color } from './Color';
import PlusIcon from '../../ui/icons/PlusIcon';
import SiteContentContext from '../../SiteContent/SiteContentContext';

//STYLED COMPONENTS
const ColorCircleWrapper = styled('div')`
  position: relative;
`;

export const ColorCirclesWrapper = styled('div')`
  display: flex;
  justify-content: center;
  height: 15px;
  position: relative;
  margin-top: 6px;
  ${ColorCircleWrapper} {
    margin: 0 2px 2px;
  }
`;

export const MoreColors = styled('div')`
  position: relative;
  top: -1px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  svg {
    color: #aaa;
    font-size: 0.8rem;
  }
  > a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    padding: 2px 3px;
  }
`;

export function splitColors(colors) {
  if (!colors) return [];
  const colorList = colors.split('\n');
  const colorObject = {};
  colorList.forEach(item => {
    const data = item.split('#');
    colorObject[data[0].toLowerCase()] = data[1];
  });
  return colorObject;
}

export function getColorList(product) {
  const data =
    product &&
    product.customFields &&
    product.customFields.filter(item => item.key === 'other-colors')[0];

  return data && data.listValues;
}

export function getColorData(product) {
  const data =
    product &&
    product.customFields &&
    product.customFields.filter(item => item.key === 'color')[0];

  return {
    title: (data && data.title) || '',
    value: (data && data.stringValue) || ''
  };
}

export function getColor(colorObject, value) {
  const lowerValue = value?.toLowerCase();
  if (typeof colorObject[lowerValue] !== 'undefined')
    return colorObject[lowerValue];
  return 'none';
}

//CIRCLE COMPONENT
export const ColorCircle = ({ value, colorObject }) => {
  const color = getColor(colorObject, value);
  if (color === 'none') return null;
  return (
    <ColorCircleWrapper>
      <Color color={color} />
    </ColorCircleWrapper>
  );
};

//ALL CIRCLES COMPONENT
export const ColorCircles = ({ product }) => {
  const otherColorslist = getColorList(product);
  const colorAmount = 7;

  //SHOW ONLY FIRST {colorAmount} COLORS
  const moreColors = otherColorslist && otherColorslist.length > colorAmount;

  const { colorList } = useContext(SiteContentContext);
  if (!colorList) return false;

  if (otherColorslist) {
    const colorObject = splitColors(colorList);
    return (
      <ColorCirclesWrapper>
        {otherColorslist.map((value, index) => {
          if (index < colorAmount)
            return (
              <ColorCircle
                colorObject={colorObject}
                key={index}
                value={value}
              />
            );
          return null;
        })}
        {moreColors && (
          <MoreColors>
            <PlusIcon />
          </MoreColors>
        )}
      </ColorCirclesWrapper>
    );
  } else return <ColorCirclesWrapper />;
};

import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import { ContentHtml, contentTextElements } from '../ContentText';

const Wrapper = styled.div``;

const TextBoxWrapper = styled('div')`
  margin: 0 auto 0;
  width: 100%;
  .text-wrapper {
    margin: 0 auto;
    max-width: 960px;
    display: flex;
    justify-content: space-between;
    ${theme.below.sm} {
      flex-direction: column;
    }
  }
  .content-html-text {
    width: 100%;
    ${contentTextElements} {
      padding-left: ${theme.general.pagePadding.sm};
      padding-right: ${theme.general.pagePadding.sm};
    }
    &.center {
      > ul {
        display: flex;
        width: 100%;
        margin: 0 auto;
        justify-content: space-around;
        flex-wrap: wrap;
        > li {
          margin: 15px;
          display: flex;
          align-items: center;
          img {
            margin-right: 15px;
          }
        }
      }
    }
  }
  padding: 2rem 0;
`;

const TextBox = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const text = props.text.value;
  const text2 = props.text2?.value;
  return (
    <Wrapper>
      <TextBoxWrapper data-index={index}>
        <div className="text-wrapper">
          <ContentHtml className={`content-html-text`} content={text} />
          {text2 && (
            <ContentHtml className={`content-html-text`} content={text2} />
          )}
        </div>
      </TextBoxWrapper>
    </Wrapper>
  );
};

export default TextBox;

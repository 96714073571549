import React, { useContext } from 'react';
import PlusIcon from '../../ui/icons/PlusIcon';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import {
  ColorCircle,
  ColorCircles,
  ColorCirclesWrapper,
  MoreColors,
  splitColors
} from './ColorCircles';

//ALL CIRCLES COMPONENT
export const VariantColors = ({ product }) => {
  const colorAmount = 7;

  const { colorList } = useContext(SiteContentContext);
  if (!colorList) return false;

  const hasColor = product.variants?.options.filter(option => {
    const nm = option.name;
    return (
      nm.includes('Farge') ||
      nm.includes('Färg') ||
      nm.includes('Farbe') ||
      nm.includes('Color') ||
      nm.includes('Colour')
    );
  })[0];

  //SEARCH FOR SIBLINGS PRODUCTS INSTEAD
  if (!product.variants || !product.variants.options || !hasColor)
    return <ColorCircles product={product} />;

  let values = [];
  product.variants.options.forEach(function(obj) {
    values = values.concat(obj.values);
  });

  //SHOW ONLY FIRST {colorAmount} COLORS
  const moreColors = values.length > colorAmount;
  const colorObject = splitColors(colorList);
  return (
    <ColorCirclesWrapper>
      {values.map((value, index) => {
        if (index < colorAmount)
          return (
            <ColorCircle colorObject={colorObject} key={index} value={value} />
          );
        return null;
      })}
      {moreColors && (
        <MoreColors>
          <PlusIcon />
        </MoreColors>
      )}
    </ColorCirclesWrapper>
  );
};

import bodyBold from './Cormorant-Bold.woff2';
import bodyLight from './Cormorant-Light.woff2';
import bodyMedium from './Cormorant-Medium.woff2';
import bodyRegular from './Cormorant-Regular.woff2';
import bodyItalic from './Cormorant-Italic.woff2';
import bodySemiBold from './Cormorant-SemiBold.woff2';

import { css } from 'linaria';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'cormorant';
        src: url(${bodyRegular}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'cormorant';
        src: url(${bodyMedium}) format('woff2');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'cormorant';
        src: url(${bodyItalic}) format('woff2');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
      }
      @font-face {
        font-family: 'cormorant';
        src: url(${bodySemiBold}) format('woff2');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'cormorant';
        src: url(${bodyLight}) format('woff2');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'cormorant';
        src: url(${bodyBold}) format('woff2');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
  `;
}

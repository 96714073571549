import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { SearchField } from '../Search';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import CartButton from '../../../Cart/CartButton';
import { Inner, PagePadding } from '../../MaxWidth';
import TopNav from '../Util/TopNav';
import Logo from '../Logo';
import SearchIcon from '../../../ui/icons/SearchIcon';
import MenuIcon from '../../../ui/icons/MenuIcon';
import { theme } from '../../../Theming/Theming';
import t from '@jetshop/intl';
import { headerHeight } from '../Header';
import { Above } from '@jetshop/ui/Breakpoints';
import { config } from '../../../../shop.config.custom';

const MobileHeaderWrapper = styled('div')`
  ${headerHeight}
  background: ${theme.mobileHeader.background};
  &[data-customheader='true'] {
    background: transparent;
    box-shadow: none;
    .header-button,
    .header-icon {
      color: white;
    }
    .number-badge {
      background: white;
      color: #000;
    }
  }
  box-shadow: 0px 1px 3px #0000000d;
  ul.top-nav-list {
    height: 100%;
    align-items: center;
    > li {
      ${theme.above.md} {
        padding: 0 1.7rem;
      }
    }
    > li > a,
    > li > button {
    }
    &.right {
      ${theme.above.md} {
        > li:nth-last-child(2) {
          padding-right: 0;
        }
        > li:last-child {
          display: none;
        }
      }
    }
  }
  .page-padding,
  .inner {
    height: 100%;
  }
  .inner {
    display: flex;
    align-items: center;
  }
  .header-icon {
    font-size: 20px;
    color: ${theme.mobileHeader.color};
  }
  .header-button {
    position: relative;
    display: flex;
    align-items: center;
  }
`;

const StoreLogo = styled('div')`
  text-align: left;
  text-decoration: none;
  color: inherit;
  width: 63px;
  margin-left: 0;
  ${theme.above.md} {
    margin-left: 50px;
    width: 90px;
  }
  position: relative;
  top: -2px;
  a {
    text-decoration: none;
    > img,
    > svg {
      max-width: 100%;
    }
  }
`;

const MenuBar = styled('div')`
  position: relative;
  ${headerHeight}
`;

const MenuButton = styled('button')``;

const SearchButtonToggle = styled('button')``;

const SearchFieldWrapper = styled('div')`
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  background: #f9f9f9;
  ${headerHeight}
  .search-wrapper {
    height: 100%;
  }
  input {
    height: 100%;
    border-bottom: none;
    background: transparent;
  }
`;

export const HeaderIconText = styled('span')`
  margin-left: 5px;
  ${theme.below.sm} {
    display: none;
  }
`;

const MobileHeader = ({ setSearch, searchOpen, scrolling, customHeader }) => {
  const softLaunch = config.settings.softLaunch;
  return (
    <MobileHeaderWrapper
      data-scrolling={scrolling}
      data-customheader={customHeader}
    >
      <MenuBar>
        <PagePadding>
          <Above breakpoint="sm">
            {(matches) => (
              <Inner className="inner">
                {matches && !softLaunch && (
                  <TopNav left>
                    <DrawerTrigger
                      id="menuDrawer"
                      coverStyles={{ zIndex: 104 }}
                    >
                      {({ showTarget, hideTarget, isOpen }) => (
                        <MenuButton
                          onClick={isOpen ? hideTarget : showTarget}
                          className="header-button"
                        >
                          <MenuIcon className="header-icon" />
                        </MenuButton>
                      )}
                    </DrawerTrigger>
                  </TopNav>
                )}

                <StoreLogo>
                  <Link to="/">
                    <Logo light={customHeader} />
                  </Link>
                </StoreLogo>
                {!softLaunch && (
                  <TopNav right>
                    {searchOpen ? (
                      <SearchFieldWrapper data-scrolling={scrolling}>
                        <SearchField
                          onCancel={() => setSearch(false)}
                          setSearch={setSearch}
                          searchOpen={searchOpen}
                          scrolling={scrolling}
                        />
                      </SearchFieldWrapper>
                    ) : (
                      <SearchButtonToggle
                        href="#"
                        onClick={() => setSearch(true)}
                        className="header-button"
                      >
                        <SearchIcon className="header-icon" />
                        <HeaderIconText>{t('Search')}</HeaderIconText>
                      </SearchButtonToggle>
                    )}
                    <CartButton className="header-button" />
                    {!matches && (
                      <DrawerTrigger
                        id="menuDrawer"
                        coverStyles={{ zIndex: 104 }}
                      >
                        {({ showTarget, hideTarget, isOpen }) => (
                          <MenuButton
                            onClick={isOpen ? hideTarget : showTarget}
                            className="header-button"
                          >
                            <MenuIcon className="header-icon" />
                          </MenuButton>
                        )}
                      </DrawerTrigger>
                    )}
                  </TopNav>
                )}
              </Inner>
            )}
          </Above>
        </PagePadding>
      </MenuBar>
    </MobileHeaderWrapper>
  );
};

export default MobileHeader;

import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import DataProvider from './Categories/CategoryProvider';
import MobileHeader from './Mobile/MobileHeader';
import MobileNav from './Mobile/MobileNav';
import CartFlyout from '../../Cart/CartFlyout';
import { theme } from '../../Theming/Theming';
import { Notifications } from '../Notifications';
import { useLocation } from 'react-router';
import { useState } from 'react';
import { useEffect } from 'react';
import ContentContext from '../../ContentContext/ContentContext';
import { config } from '../../../shop.config.custom';

export const headerHeight = `
  height: ${theme.mobileHeader.height.sm + 'px'};
  ${theme.above.md}{
    height: ${theme.mobileHeader.height.md + 'px'};
  }
  ${theme.above.lg}{
    height: ${theme.mobileHeader.height.lg + 'px'};
  }
`;

const MobileHeaderWrapper = styled('header')`
  position: fixed;
  z-index: 104;
  top: 0;
  left: 0;
  width: 100%;
  a {
    text-decoration: none;
    &:hover {
    }
  }
`;
const MobileHeaderSpacer = styled('div')`
  ${headerHeight}
`;

const Header = ({ categoryTreeRoots, categories }) => {
  const [searchOpen, setSearch] = useState(false);
  const [scrolling, setScroll] = useState(false);
  const location = useLocation();
  const isStart = location.pathname === '/';
  const { hasContent } = useContext(ContentContext);

  const customHeader = isStart || (hasContent && !scrolling);

  const handleScroll = (event) => {
    if (typeof window === 'undefined') return false;

    if (window.scrollY > theme.header.scrollBreak && !scrolling) {
      setScroll(true);
    } else if (window.scrollY === 0 && scrolling) {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <React.Fragment>
      {!isStart && !hasContent && (
        <MobileHeaderSpacer data-scrolling={scrolling} />
      )}
      <MobileHeaderWrapper id="main-header">
        <MobileHeader
          searchOpen={searchOpen}
          setSearch={setSearch}
          scrolling={scrolling}
          customHeader={customHeader}
        />
      </MobileHeaderWrapper>
      {!config.settings.softLaunch && (
        <>
          <DrawerTarget id="menuDrawer" showCover={true}>
            {({ isOpen, hideTarget }) => (
              <MobileNav
                categories={categories}
                categoryTreeRoots={categoryTreeRoots}
                onClick={hideTarget}
                isOpen={isOpen}
                hideTarget={hideTarget}
              />
            )}
          </DrawerTarget>
          <CartFlyout />
        </>
      )}

      <Notifications />
    </React.Fragment>
  );
};

const HeaderComponent = () => (
  <DataProvider>{(props) => <Header {...props} />}</DataProvider>
);

export default HeaderComponent;

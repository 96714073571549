import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import CampaignBar from './CampaignBar';
import { Below } from '@jetshop/ui/Breakpoints';

const ContentWrapper = styled('main')`
  flex: 1 1 auto;
  //padding-top: 1rem;
`;

export const ContentPadding = `
  padding-bottom: 2rem;
  ${theme.above.md} {
    padding-bottom: 4rem;
  }
`;

const MainBackground = styled('div')`
  //background: ${theme.colors.secondary};
`;

const Content = ({ children }) => {
  return (
    <ContentWrapper>
      <Below breakpoint="lg">{matches => matches && <CampaignBar />}</Below>
      <MainBackground>{children}</MainBackground>
    </ContentWrapper>
  );
};

export default Content;

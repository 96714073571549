import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import NewsletterField from './Newsletter/NewsletterField';
import Logo from '../Header/Logo';
import { convertPropsToObject } from '../../ContentRender/ContentRender';
import { Link } from 'react-router-dom';
import RightIcon from '../../ui/icons/RightIcon';
import { useState } from 'react';
import { config } from '../../../shop.config.custom';

const FooterWrapper = styled('footer')`
  padding: 4rem 0.5rem 0;
  background: ${theme.footer.background};
  z-index: 2;
  a,
  a:active {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  display: flex;
  flex-direction: column;
  .upper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .newsletter-box {
      width: 31%;
      ${theme.below.md} {
        width: 47%;
      }
      ${theme.below.sm} {
        width: 100%;
        margin-bottom: 1rem;
      }
      text-align: center;
      h2,
      h3,
      h4,
      h5 {
        margin-bottom: 0;
      }
    }
    .footer-blocks {
      width: 64%;
      ${theme.below.md} {
        width: 47%;
      }
      ${theme.below.sm} {
        width: 100%;
      }
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        width: 47%;
        ${theme.below.md} {
          width: 100%;
        }
        margin-bottom: 1rem;
        h4 {
          display: flex;
          justify-content: space-between;
          svg {
            transtion: all 0.5s smooth;
            transform: rotate(90deg);
          }
          &.open {
            svg {
              transform: rotate(270deg);
            }
          }
        }
        h4:not(.link),
        svg {
          cursor: pointer;
        }
      }
    }
  }
  .logo {
    margin: 3rem 0 0 0;
    padding: 4rem 0 0 0;
    text-align: center;
    border-top: 1px solid ${theme.colors.border};
    ${theme.below.sm} {
      order: -1;
      border-top: none;
      border-bottom: 1px solid ${theme.colors.border};
      margin: 0 0 3rem 0;
      padding: 0 0 2rem 0;
    }
  }
  .bottom {
    padding: 2rem 0 4rem;
    text-align: center;
    ${theme.below.sm} {
      margin-top: 2rem;
      border-top: 1px solid ${theme.colors.border};
    }
  }
`;

const FooterBlock = ({ block }) => {
  const props = convertPropsToObject(block);
  const link = props.link?.value;
  const title = props.title?.value;
  const children = block.children?.length > 0;
  const [open, setOpen] = useState(false);

  return (
    <div>
      <h4
        className={`${link ? 'link' : ''} ${open ? 'open' : ''}`}
        onClick={() => setOpen(!open)}
      >
        {link ? <Link to={link}>{title}</Link> : <>{title}</>}
        {children && <RightIcon />}
      </h4>
      {children && open && (
        <ul>
          {block.children.map((child, index) => {
            const props = convertPropsToObject(child);
            const link = props.link?.value;
            const title = props.title?.value;
            return (
              <li key={index}>
                <Link to={link}>{title}</Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const Footer = () => {
  const { footer, footerBlocks } = useContext(SiteContentContext);
  return (
    <FooterWrapper className="footer-wrapper">
      <MaxWidth>
        {!config.settings.softLaunch && (
          <div className="upper">
            <div className="newsletter-box">
              <div className="text">{footer?.newsletterText}</div>
              <NewsletterField />
            </div>
            <div className="footer-blocks">
              {footerBlocks?.map((block, index) => (
                <FooterBlock key={index} block={block} />
              ))}
            </div>
          </div>
        )}
        <div className="logo">
          <Logo />
          <h4>{footer?.logoSlogan}</h4>
        </div>
        <div className="bottom panel">
          <div>{footer?.footerBottom}</div>
        </div>
      </MaxWidth>
    </FooterWrapper>
  );
};

export default Footer;

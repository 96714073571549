import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import { RowHeader } from './RowHeader';
import ProductRow from '../../ui/ProductRow/ProductRow';
import { pagePaddingCss } from '../../Layout/MaxWidth';

const ProductRowComponentWrapper = styled('div')`
  background: white;
  ${theme.below.md} {
    padding-top: 1rem;
  }
  ${theme.above.lg} {
    padding: 1% 0;
    > div {
      display: flex;
      align-items: center;
      ${pagePaddingCss}
    }
    .row-header {
      width: 20%;
      margin: 0;
      padding: 0 1rem;
      text-align: center;
    }
    .product-row-outer-wrapper {
      width: 80%;
    }
  }
  .row-header {
    margin: 0 0 2rem 0;
    ${theme.below.md} {
      margin: 0 1rem 0;
    }
  }
`;

const ProductRowComponent = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const rowProps = {
    id: props.category.value && props.category.value.id,
    limit: props.limit.value,
    orderBy: props.orderBy.value,
    orderByDirection: props.orderByDirection.value,
    slider: props.slider.value === 'yes' ? true : false
  };

  if (!rowProps.id) return null;
  return (
    <ProductRowComponentWrapper data-index={index}>
      <div>
        <RowHeader props={props} />
        <ProductRow {...rowProps} />
      </div>
    </ProductRowComponentWrapper>
  );
};

export default ProductRowComponent;
